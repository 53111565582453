import config from 'helpers/config'
import {
  FETCH_PATHWAYS_REQUEST,
  RECEIVE_PATHWAYS,
  RECEIVE_PATHWAYS_ERROR,
} from 'redux/constants/ActionTypes'

import { updateObject } from './utils'

const initialState = config.reducers.collections.initialState

export default function pathways(state = initialState, action) {
  switch (action.type) {
    case FETCH_PATHWAYS_REQUEST: {
      return updateObject(state, {
        currentLocale: action.currentLocale || state.currentLocale,
        error: null,
        loading: true,
      })
    }
    case RECEIVE_PATHWAYS: {
      const entities = action.response.pathways || {}
      const entitiesWithLinks = Object.assign(
        ...Object.entries(entities).map(([key, entity]) => ({
          [key]: {
            linkUrl: `/pathways/${entity.slug}`,
            heroImage: `https://projects-static.raspberrypi.org/pathways/assets/${entity.slug}.png`,
            ...entity,
          },
        }))
      )
      return updateObject(state, {
        entities: entitiesWithLinks,
        links: action.response.links,
        loading: false,
        meta: action.response.meta,
      })
    }
    case RECEIVE_PATHWAYS_ERROR: {
      return updateObject(state, {
        entities: {},
        error: action.error,
        links: {},
        loading: false,
        meta: {},
      })
    }
    default: {
      return state
    }
  }
}
