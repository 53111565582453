// Adapted from https://github.com/reactjs/redux/blob/734a2833e801b68cf64464d55ac98e8d75568004/examples/real-world/src/middleware/api.js
import { curry } from 'lodash'
import { LOAD_FILE } from 'redux/constants/ActionTypes'

const loadExercise = (slug) => {
  const getManifest = new Promise((resolve, reject) => {
    import(`../../assets/exercises/${slug}/manifest.json`)
      .then((manifest) => {
        setTimeout(() => {
          return resolve(manifest)
        }, 500) //TODO remove this, it's to illustrate loading state
      })
      .catch(() => {
        reject('manifest not found')
      })
  })

  const getCode = new Promise((resolve, reject) => {
    import(`../../assets/exercises/${slug}/code.js`)
      .then((code) => {
        resolve(code.default)
      })
      .catch(() => {
        reject('Code not found')
      })
  })

  return Promise.all([getManifest, getCode])
    .then((data) => {
      const project = {
        ...data[0],
        code: data[1],
      }

      return Promise.resolve(project)
    })
    .catch((_) => {
      return Promise.reject({
        message: 'Not found',
        status: 404,
        body: {},
      })
    })
}

export const exerciseMiddleware = (next, action) => {
  const loadFILE = action[LOAD_FILE]

  if (typeof loadFILE === 'undefined') {
    return next(action)
  }

  const { types, slug, options = {}, actions, ...extras } = loadFILE

  // This middleware expects to receive an array of 3 action types
  // e.g. `types: ['FETCH_PROJECT_REQUEST', 'RECEIVE_PROJECT', 'RECEIVE_ERROR']`
  //
  // It uses these to handle api requests, the first to get data,
  // the second to handle success responses and the last failure responses.
  //

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }

  const actionWith = (actionData) => {
    const finalAction = Object.assign({}, action, actionData)
    delete finalAction[LOAD_FILE]
    return finalAction
  }
  const [requestType, successType, failureType] = types

  next(
    actionWith({
      type: requestType,
      ...loadFILE,
    })
  )

  return loadExercise(slug).then(
    (response) => {
      next(
        actionWith({
          response,
          type: successType,
          extras,
          actions,
        })
      )
    },
    (error) => {
      next(
        actionWith({
          type: failureType,
          error: error || {
            message: 'Something went wrong',
            status: 500,
          },
          actions,
        })
      )
    }
  )
}

export default () => curry(exerciseMiddleware)
