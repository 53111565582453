import { reducer as authentication } from 'helpers/authentication'
import { combineReducers } from 'redux'

import badge from './badge'
import collection from './collection'
import collections from './collections'
import exercise from './exercise'
import favourites from './favourites'
import featured from './featured'
import pathway from './pathway'
import pathways from './pathways'
import progress from './progress'
import project from './project'
import projectProgress from './projectProgress'
import projects from './projects'
import quiz from './quiz'
import quizAttempt from './quizAttempt'

export default combineReducers({
  favourites,
  featured,
  badge,
  collection,
  collections,
  exercise,
  pathway,
  pathways,
  projectProgress,
  progress,
  project,
  projects,
  quiz,
  quizAttempt,
  authentication,
})
