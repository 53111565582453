import { useCookies } from 'react-cookie'
import { v4 as uuidv4 } from 'uuid'

const SessionCookie = () => {
  const [cookies, setCookie] = useCookies(['sessionProgress'])

  if (!cookies.sessionProgress) {
    setCookie('sessionProgress', uuidv4(), { path: '/' })
  }

  return null
}

export default SessionCookie
