import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import './Dropdown.css'

const optionsMap = (options) => {
  let optionsKeys = Object.keys(options)

  return optionsKeys.map((optionsKey) => (
    <option key={optionsKey} value={optionsKey}>
      {options[optionsKey]}
    </option>
  ))
}

const Dropdown = ({
  changeHandler,
  className,
  label,
  name,
  options,
  value,
  blankText,
}) => (
  <select
    aria-label={label}
    className={classNames(className, 'c-dropdown')}
    name={name}
    onChange={(e) => changeHandler(e.target.value)}
    value={value}
  >
    {Boolean(blankText) && <option>-- {blankText} --</option>}
    {optionsMap(options)}
  </select>
)

Dropdown.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  blankText: PropTypes.string,
}

export default Dropdown
