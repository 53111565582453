import config from 'helpers/config'
import {
  FETCH_PROJECT_PROGRESS_REQUEST,
  RECEIVE_PROJECT_PROGRESS,
  RECEIVE_PROJECT_PROGRESS_ERROR,
  SEND_PROGRESS_ERROR,
  SEND_PROGRESS_REQUEST,
  SENT_PROGRESS_OK,
  SET_PROJECT_STEP,
} from 'redux/constants/ActionTypes'

import { updateObject } from './utils'

const initialState = config.reducers.progress.initialState

export default function progress(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROJECT_PROGRESS_REQUEST: {
      return updateObject(state, {
        currentLocale: action.currentLocale || state.currentLocale,
        error: null,
        loading: true,
      })
    }
    case RECEIVE_PROJECT_PROGRESS: {
      return updateObject(state, {
        slug: action.response.repositoryName,
        currentStep: action.response.step,
        loading: false,
      })
    }
    case RECEIVE_PROJECT_PROGRESS_ERROR: {
      return updateObject(state, {
        error: action.error,
        loading: false,
      })
    }
    case SEND_PROGRESS_REQUEST: {
      return updateObject(state, {
        error: null,
        sending: true,
        sent: false,
      })
    }
    case SENT_PROGRESS_OK: {
      return updateObject(state, {
        sending: false,
        sent: true,
        currentStep: action.response.step,
      })
    }
    case SEND_PROGRESS_ERROR: {
      return updateObject(state, {
        error: action.error,
        sending: false,
        sent: true,
      })
    }
    case SET_PROJECT_STEP: {
      return updateObject(state, {
        sent: false,
      })
    }
    default: {
      return state
    }
  }
}
