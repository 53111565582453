import config from 'helpers/config'
import {
  FETCH_PROJECTS_REQUEST,
  RECEIVE_PROJECTS,
  RECEIVE_PROJECTS_ERROR,
  SEARCH_PROJECTS_ERROR,
  SEARCH_PROJECTS_REQUEST,
  SEARCH_PROJECTS_RESPONSE,
} from 'redux/constants/ActionTypes'

import { updateObject } from './utils'

const initialState = config.reducers.collections.initialState

export default function projects(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROJECTS_REQUEST: {
      return updateObject(state, {
        currentLocale: action.currentLocale || state.currentLocale,
        error: null,
        loading: true,
      })
    }
    case RECEIVE_PROJECTS: {
      return updateObject(state, {
        entities: action.response.projects || {},
        links: action.response.links,
        loading: false,
        meta: action.response.meta,
      })
    }
    case RECEIVE_PROJECTS_ERROR: {
      return updateObject(state, {
        entities: {},
        error: action.error,
        links: {},
        loading: false,
        meta: {},
      })
    }
    case SEARCH_PROJECTS_REQUEST: {
      return updateObject(state, {
        currentLocale: action.currentLocale || state.currentLocale,
        error: null,
        loading: true,
      })
    }
    case SEARCH_PROJECTS_RESPONSE: {
      return updateObject(state, {
        entities: action.response.projects || {},
        links: action.response.links,
        loading: false,
        meta: action.response.meta,
      })
    }
    case SEARCH_PROJECTS_ERROR: {
      return updateObject(state, {
        entities: {},
        error: action.error,
        links: {},
        loading: false,
        meta: {},
      })
    }
    default: {
      return state
    }
  }
}
