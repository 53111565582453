import config from 'helpers/config'
import PropTypes from 'prop-types'
import { Button, TypeBase, TypeDisplay } from 'raspberry-pi-bits'
import React from 'react'
import { translate } from 'react-i18next'

import './NoMatch.css'

const errorDialogImages = () => {
  let images = []
  let left
  let numImages = Math.ceil(Math.random() * 10) + 5
  let screenHeight = 93
  let screenWidth = 172
  let top

  for (let i = 0; i < numImages; i += 1) {
    left = Math.floor(Math.random() * screenWidth)
    top = Math.floor(Math.random() * screenHeight)
    images.push(
      <span
        className="c-error__dialog"
        key={i}
        style={{ left: left, top: top }}
      />
    )
  }
  return images
}

export const NoMatch = ({ error, t }) => {
  if (!error) {
    return <div />
  }
  let errorMsg = t('no-match.title.text')
  if (error.status !== config.notFoundStatus) {
    errorMsg = t('error-boundary.message')
  }
  return (
    <div className={`c-error c-error--${error.status}`}>
      <div className="c-error__wrapper u-clearfix">
        <div className="c-error__image">
          <span className="c-error__screen">{errorDialogImages()}</span>
        </div>
        <div className="c-error__body">
          <TypeDisplay element="h1">{errorMsg}</TypeDisplay>
          {error.status === config.notFoundStatus ? (
            ''
          ) : (
            <TypeBase element="p">{t('no-match.message.notified')}</TypeBase>
          )}
          <TypeBase element="p">{t('no-match.message.text')}</TypeBase>
          <Button to="/">{t('no-match.button.text')}</Button>
        </div>
      </div>
    </div>
  )
}
NoMatch.propTypes = {
  error: PropTypes.object,
  t: PropTypes.func.isRequired,
}

export default translate('translations')(NoMatch)
