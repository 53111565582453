import config from 'helpers/config'
import {
  FETCH_PROGRESS_REQUEST,
  RECEIVE_PROGRESS,
  RECEIVE_PROGRESS_ERROR,
  SEND_PROGRESS_ERROR,
  SEND_PROGRESS_REQUEST,
  SENT_PROGRESS_OK,
} from 'redux/constants/ActionTypes'

import { updateObject } from './utils'

const initialState = config.reducers.collections.initialState

export default function progress(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROGRESS_REQUEST: {
      return updateObject(state, {
        currentLocale: action.currentLocale || state.currentLocale,
        error: null,
        loading: true,
      })
    }
    case RECEIVE_PROGRESS: {
      const entities = action.response.project || {}
      const ids = Object.values(entities).map((entity) => entity.id)
      return updateObject(state, {
        entities,
        ids,
        loading: false,
      })
    }
    case RECEIVE_PROGRESS_ERROR: {
      return updateObject(state, {
        entities: {},
        error: action.error,
        ids: [],
        loading: false,
      })
    }
    case SEND_PROGRESS_REQUEST: {
      return updateObject(state, {
        error: null,
        sending: true,
        sent: false,
      })
    }
    case SENT_PROGRESS_OK: {
      const ids = state.ids.slice()
      const projectIdStr = action.response.projectId.toString()
      let index = ids.indexOf(projectIdStr)
      if (index === -1) {
        index = ids.length
        ids.push(projectIdStr)
      }
      return updateObject(state, {
        entities: {
          ...state.entities,
          [index]: {
            ...state.entities[index],
            currentStep: action.response.step,
            quizCompleted: action.response.quizCompleted || false,
          },
        },
        loading: false,
        ids,
      })
    }
    case SEND_PROGRESS_ERROR: {
      return updateObject(state, {
        error: action.error,
        loading: false,
      })
    }
    default: {
      return state
    }
  }
}
