import { createBrowserHistory } from 'history'

let history

const setUpHistory = (i18n) => {
  if (!history) {
    history = createBrowserHistory()

    history.listen((location) => {
      const currentLocale = i18n.language
      const newLocale = location.pathname.split('/')[1]
      if (newLocale !== currentLocale) {
        i18n.changeLanguage(newLocale)
      }
    })
  }

  return history
}

export default setUpHistory
