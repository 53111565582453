import PropTypes from 'prop-types'
import React from 'react'

import './Banner.css'

const Banner = ({ text = '' }) => (
  <div className="c-banner">
    <span>{text}</span>
  </div>
)

Banner.propTypes = {
  text: PropTypes.string,
}

export { Banner }
export default Banner
