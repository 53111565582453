// UI actions
export const SET_PROJECT_STEP = 'SET_PROJECT_STEP'
export const INIT_QUIZ_ATTEMPT = 'INIT_QUIZ_ATTEMPT'
export const ADD_ANSWER_TO_CURRENT_ATTEMPT = 'ADD_ANSWER_TO_CURRENT_ATTEMPT'
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION'

// Middleware meta-actions
export const CALL_API = 'CALL_API'
export const LOAD_FILE = 'LOAD_FILE'

// List requests
export const FETCH_COLLECTIONS_REQUEST = 'FETCH_COLLECTIONS_REQUEST'
export const RECEIVE_COLLECTIONS = 'RECEIVE_COLLECTIONS'
export const RECEIVE_COLLECTIONS_ERROR = 'RECEIVE_COLLECTIONS_ERROR'

export const FETCH_PATHWAYS_REQUEST = 'FETCH_PATHWAYS_REQUEST'
export const RECEIVE_PATHWAYS = 'RECEIVE_PATHWAYS'
export const RECEIVE_PATHWAYS_ERROR = 'RECEIVE_PATHWAYS_ERROR'

export const FETCH_PROJECTS_REQUEST = 'FETCH_PROJECTS_REQUEST'
export const RECEIVE_PROJECTS = 'RECEIVE_PROJECTS'
export const RECEIVE_PROJECTS_ERROR = 'RECEIVE_PROJECTS_ERROR'

export const FETCH_FAVOURITES_REQUEST = 'FETCH_FAVOURITES_REQUEST'
export const RECEIVE_FAVOURITES = 'RECEIVE_FAVOURITES'
export const RECEIVE_FAVOURITES_ERROR = 'RECEIVE_FAVOURITES_ERROR'

export const FETCH_FEATURED_REQUEST = 'FETCH_FEATURED_REQUEST'
export const RECEIVE_FEATURED = 'RECEIVE_FEATURED'
export const RECEIVE_FEATURED_ERROR = 'RECEIVE_FEATURED_ERROR'

export const SEARCH_PROJECTS_REQUEST = 'SEARCH_PROJECTS_REQUEST'
export const SEARCH_PROJECTS_RESPONSE = 'SEARCH_PROJECTS_RESPONSE'
export const SEARCH_PROJECTS_ERROR = 'SEARCH_PROJECTS_ERROR'

export const FETCH_PROGRESS_REQUEST = 'FETCH_PROGRESS_REQUEST'
export const RECEIVE_PROGRESS = 'RECEIVE_PROGRESS'
export const RECEIVE_PROGRESS_ERROR = 'RECEIVE_PROGRESS_ERROR'

// Item requests
export const FETCH_COLLECTION_REQUEST = 'FETCH_COLLECTION_REQUEST'
export const RECEIVE_COLLECTION = 'RECEIVE_COLLECTION'
export const RECEIVE_COLLECTION_ERROR = 'RECEIVE_COLLECTION_ERROR'

export const FETCH_EXERCISE_REQUEST = 'FETCH_EXERCISE_REQUEST'
export const RECEIVE_EXERCISE = 'RECEIVE_EXERCISE'
export const RECEIVE_EXERCISE_ERROR = 'RECEIVE_EXERCISE_ERROR'

export const FETCH_PATHWAY_REQUEST = 'FETCH_PATHWAY_REQUEST'
export const RECEIVE_PATHWAY = 'RECEIVE_PATHWAY'
export const RECEIVE_PATHWAY_ERROR = 'RECEIVE_PATHWAY_ERROR'

export const FETCH_PROJECT_REQUEST = 'FETCH_PROJECT_REQUEST'
export const RECEIVE_PROJECT = 'RECEIVE_PROJECT'
export const RECEIVE_PROJECT_ERROR = 'RECEIVE_PROJECT_ERROR'

export const CLEAR_BADGE_STATE = 'CLEAR_BADGE_STATE'
export const FETCH_BADGE_REQUEST = 'FETCH_BADGE_REQUEST'
export const RECEIVE_ISSUED_BADGE = 'RECEIVE_ISSUED_BADGE'
export const RECEIVE_BADGE_TEMPLATE = 'RECEIVE_BADGE_TEMPLATE'
export const RECEIVE_ISSUED_BADGE_ERROR = 'RECEIVE_ISSUED_BADGE_ERROR'
export const RECEIVE_BADGE_TEMPLATE_ERROR = 'RECEIVE_BADGE_TEMPLATE_ERROR'

export const FETCH_PROJECT_PROGRESS_REQUEST = 'FETCH_PROJECT_PROGRESS_REQUEST'
export const RECEIVE_PROJECT_PROGRESS = 'RECEIVE_PROJECT_PROGRESS'
export const RECEIVE_PROJECT_PROGRESS_ERROR = 'RECEIVE_PROJECT_PROGRESS_ERROR'

export const FETCH_QUIZ_REQUEST = 'FETCH_QUIZ_REQUEST'
export const RECEIVE_QUIZ = 'RECEIVE_QUIZ'
export const RECEIVE_QUIZ_ERROR = 'RECEIVE_QUIZ_ERROR'

// Save requests
export const SEND_FAVOURITES_REQUEST = 'SEND_FAVOURITES_REQUEST'
export const SENT_FAVOURITES_OK = 'SENT_FAVOURITES_OK'
export const SEND_DELETE_FAVOURITES_REQUEST = 'SEND_DELETE_FAVOURITES_REQUEST'
export const SENT_DELETE_FAVOURITES_OK = 'SENT_DELETE_FAVOURITES_OK'
export const SEND_FAVOURITES_ERROR = 'SEND_FAVOURITES_ERROR'

export const SEND_PROGRESS_REQUEST = 'SEND_PROGRESS_REQUEST'
export const SENT_PROGRESS_OK = 'SENT_PROGRESS_OK'
export const SEND_PROGRESS_ERROR = 'SEND_PROGRESS_ERROR'

export const SEND_RECONCILE_USER_REQUEST = 'SEND_RECONCILE_USER_REQUEST'
export const SENT_RECONCILE_USER_OK = 'SENT_RECONCILE_USER_OK'
export const SEND_RECONCILE_USER_ERROR = 'SEND_RECONCILE_USER_ERROR'

export const SEND_QUIZ_ANSWER_REQUEST = 'SEND_QUIZ_ANSWER_REQUEST'
export const SENT_QUIZ_ANSWER_OK = 'SENT_QUIZ_ANSWER_OK'
export const SEND_QUIZ_ANSWER_ERROR = 'SEND_QUIZ_ANSWER_ERROR'

// Generic API errors
export const RECEIVE_ERROR = 'RECEIVE_ERROR'
export const SEND_ERROR = 'SEND_ERROR'
