import config from 'helpers/config'
import {
  FETCH_QUIZ_REQUEST,
  RECEIVE_QUIZ,
  RECEIVE_QUIZ_ERROR,
} from 'redux/constants/ActionTypes'

import { updateObject } from './utils'

const initialState = config.reducers.quiz.initialState

export default function quiz(state = initialState, action) {
  switch (action.type) {
    case FETCH_QUIZ_REQUEST: {
      return updateObject(state, {
        currentLocale: action.currentLocale || state.currentLocale,
        error: null,
        loading: true,
      })
    }
    case RECEIVE_QUIZ: {
      const fetchedQuiz = action.response.content || {}
      return updateObject(fetchedQuiz, {
        currentLocale: action.extras.currentLocale || state.currentLocale,
        fullPath: `${action.extras.currentProject}/quizzes/${fetchedQuiz.path}`,
        id: action.response.id,
        loading: false,
        slideDirection: 'left',
      })
    }
    case RECEIVE_QUIZ_ERROR: {
      return updateObject(state, {
        error: action.error,
        loading: false,
        questions: [],
      })
    }
    default: {
      return state
    }
  }
}
