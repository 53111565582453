import Dropdown from 'components/shared/Dropdown/Dropdown'
import setUpHistory from 'helpers/history'
import PropTypes from 'prop-types'
import React from 'react'
import { translate } from 'react-i18next'

export const changeLanguageHandler = (i18n, newLocale) => {
  if (!newLocale) {
    return null
  }

  let path = window.location.pathname.split('/')
  path[1] = newLocale
  path = path.join('/')

  setUpHistory(i18n).push({
    pathname: path,
  })

  return null
}

export const LanguageSelector = ({ i18n, t, filter }) => {
  const options = t('language-selector.options', { returnObjects: true })
  let locales = filter ? filter(options) : options

  return (
    <Dropdown
      changeHandler={(val) => changeLanguageHandler(i18n, val)}
      label={t('language-selector.label')}
      name="locale"
      options={{ ...locales }}
      value={i18n.language}
      blankText={t('language-selector.label')}
    />
  )
}

LanguageSelector.propTypes = {
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  filter: PropTypes.func,
}

export default translate('translations')(LanguageSelector)
