import config from 'helpers/config'
import {
  FETCH_COLLECTIONS_REQUEST,
  RECEIVE_COLLECTIONS,
  RECEIVE_COLLECTIONS_ERROR,
} from 'redux/constants/ActionTypes'

import { updateObject } from './utils'

const initialState = config.reducers.collections.initialState

export default function collections(state = initialState, action) {
  switch (action.type) {
    case FETCH_COLLECTIONS_REQUEST: {
      return updateObject(state, {
        currentLocale: action.currentLocale || state.currentLocale,
        error: null,
        loading: true,
      })
    }
    case RECEIVE_COLLECTIONS: {
      const entities = action.response.collections || {}
      const entitiesWithImages = Object.assign(
        ...Object.entries(entities).map(([key, entity]) => ({
          [key]: {
            heroImage: `https://projects-static.raspberrypi.org/collections/assets/${entity.slug}.png`,
            ...entity,
          },
        }))
      )
      return updateObject(state, {
        entities: entitiesWithImages,
        links: action.response.links,
        loading: false,
        meta: action.response.meta,
      })
    }
    case RECEIVE_COLLECTIONS_ERROR: {
      return updateObject(state, {
        entities: {},
        error: action.error,
        links: {},
        loading: false,
        meta: {},
      })
    }
    default: {
      return state
    }
  }
}
