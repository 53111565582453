import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

export const archivedProjects = [
  'microbit-game-controller',
  'n-days-of-christmas',
  'sweet-shop-reaction-game',
]

export const LegacyRedirect = ({ to }) => {
  window.location.replace(to)
  return null
}

LegacyRedirect.propTypes = {
  to: PropTypes.string,
}

const loadArchivedRoutes = () => {
  return archivedProjects.map((project, idx) => {
    return (
      <Route
        path={`/:locale/projects/${project}`}
        key={idx}
        element={
          <LegacyRedirect
            to={`https://github.com/raspberrypilearning/${project}`}
          />
        }
      />
    )
  })
}

export default loadArchivedRoutes
