import config from 'helpers/config'
import PropTypes from 'prop-types'
import React from 'react'
import { translate } from 'react-i18next'

export const LicenseInfo = ({ projectRepoUrl, t }) => {
  const projectLicenseInfo = () => {
    if (!projectRepoUrl) {
      return null
    }

    return (
      <React.Fragment>
        {' '}
        {t('project.footer.license.under')}{' '}
        <a
          className="c-project-footer__link c-project-footer__link--creative-commons"
          href={config.creativeCommonsUrl}
        >
          Creative Commons license
        </a>
        .<br />
        <a
          className="c-project-footer__link c-project-footer__link--github"
          href={projectRepoUrl}
        >
          {t('project.footer.license.link.text')}
        </a>
      </React.Fragment>
    )
  }

  return (
    <p>
      {t('project.footer.license.published')}{' '}
      <a
        className="c-project-footer__link c-project-footer__link--rpi"
        href={config.raspberryPiUrl}
      >
        Raspberry Pi Foundation
      </a>
      {projectLicenseInfo()}
    </p>
  )
}

LicenseInfo.propTypes = {
  projectRepoUrl: PropTypes.string,
  t: PropTypes.func.isRequired,
}

export default translate('translations')(LicenseInfo)
