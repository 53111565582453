import { stepPosition, stepSlideDirection } from 'helpers/Step'
import config from 'helpers/config'
import i18n from 'i18next'
import {
  FETCH_PROJECT_REQUEST,
  RECEIVE_PROJECT,
  RECEIVE_PROJECT_ERROR,
  SET_PROJECT_STEP,
} from 'redux/constants/ActionTypes'

import { updateObject } from './utils'

const initialState = config.reducers.project.initialState

export default function project(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROJECT_REQUEST: {
      const currentStepPosition = stepPosition(action.currentStepPosition)
      return updateObject(state, {
        currentLocale: action.currentLocale || state.currentLocale,
        currentStepPosition,
        error: null,
        loading: true,
        project: {},
        projectFetched: 0,
        slideDirection: 'left',
        slug: action.slug,
      })
    }
    case RECEIVE_PROJECT: {
      const fetchedProject = updateObject(action.response.content, {
        id: action.response.id,
        locale: action.response.locale,
        archived: action.response.archived,
        unskippable: action.response.unskippable,
        included: action.response?.included,
        pathwayStep: action.response?.pathwayStep,
      })
      return updateObject(state, {
        nextStepTitle: fetchedProject.steps[state.currentStepPosition + 1]
          ? fetchedProject.steps[state.currentStepPosition + 1].title
          : i18n.t('project.steps.navigation.whats-next'),
        previousStepTitle:
          fetchedProject.steps[state.currentStepPosition - 1]?.title,
        project: fetchedProject,
        projectFetched: 1,
        loading: false,
      })
    }
    case RECEIVE_PROJECT_ERROR: {
      const fetchedProject = action.error.body || {}
      // TODO, CR, 24/06/24: fix the API so it returns JSON when there's an invalid locale
      // URL example: https://learning-admin-staging.herokuapp.com/api/v1/ff/favourites
      const jsonReply = !!action.error.body

      return updateObject(state, {
        currentStepPosition: null,
        error: action.error,
        loading: false,
        project: fetchedProject,
        projectFetched: jsonReply
          ? action.error.body.availableLocales
            ? 1
            : -1
          : -1,
        slug: null,
      })
    }
    case SET_PROJECT_STEP: {
      const currentStepPosition = stepPosition(action.currentStepPosition)
      const nextStepTitle =
        state.project.steps && state.project.steps[currentStepPosition + 1]
          ? state.project.steps[currentStepPosition + 1].title
          : i18n.t('project.steps.navigation.whats-next')
      const previousStepTitle =
        state.project.steps &&
        state.project.steps[currentStepPosition - 1]?.title
      const slideDirection = stepSlideDirection(
        currentStepPosition,
        state.currentStepPosition
      )
      return updateObject(state, {
        currentStepPosition,
        nextStepTitle,
        previousStepTitle,
        slideDirection,
      })
    }
    default: {
      return state
    }
  }
}
