import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

const NavItemLink = (props) => {
  const {
    listKey,
    locale,
    linkTo,
    external = false,
    onClick,
    className = '',
    translateKey,
    t,
  } = props

  const localedLink = locale ? `/${locale}/${linkTo}` : linkTo

  const attrs = {
    className: classNames('c-site-header__nav-link', className),
    ...(external || onClick ? { href: localedLink } : { to: localedLink }),
    ...(onClick && { onClick }),
  }

  const link =
    external || onClick ? (
      <a {...attrs}>{t(`navigation.${translateKey}`)}</a>
    ) : (
      <Link {...attrs}>{t(`navigation.${translateKey}`)}</Link>
    )

  return (
    <li
      className="c-site-header-nav__item"
      data-js="nav-item"
      data-section={listKey}
    >
      {link}
    </li>
  )
}

NavItemLink.propTypes = {
  listKey: PropTypes.string.isRequired,
  translateKey: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  external: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  locale: PropTypes.string,
  t: PropTypes.func.isRequired,
}

export default NavItemLink
