export default {
  okStatus: 200,
  createdStatus: 201,
  noContentStatus: 204,
  redirectedStatus: 300,
  notFoundStatus: 404,
  internalServerErrorStatus: 500,
  initialScrollDuration: 150,
  stepScrollDuration: 250,
  transitionEnterTimeout: 325,
  transitionExitTimeout: 325,

  creativeCommonsUrl: 'https://creativecommons.org/licenses/by-sa/4.0/',
  raspberryPiUrl: 'https://www.raspberrypi.org',

  projectCompleteSlug: 'complete',
  projectCompleteTitle: 'Project Complete',
  reducers: {
    badge: {
      initialState: {
        badge: {},
        badgeFetched: false,
        error: null,
        loading: false,
        issued: false,
      },
    },
    collections: {
      initialState: {
        entities: {},
        error: null,
        ids: [],
        links: {},
        loading: true,
        meta: {},
      },
    },
    collection: {
      initialState: {
        currentLocale: 'en',
        error: null,
        loading: true,
        title: '',
        description: '',
        pathways: [],
        additionalPathways: [],
      },
    },
    featured: {
      initialState: {
        currentLocale: 'en',
        error: null,
        ids: [],
        loading: false,
        entities: {},
      },
    },
    pathway: {
      initialState: {
        currentLocale: 'en',
        description: '',
        error: null,
        loading: true,
        projectsIds: [],
        title: '',
        slug: '',
        header: [],
      },
    },
    progress: {
      initialState: {
        currentLocale: 'en',
        currentStep: 0,
        error: null,
        loading: false,
        sending: false,
        sent: true,
        slug: null,
      },
    },
    project: {
      initialState: {
        currentLocale: 'en',
        currentStepPosition: 0,
        error: null,
        loading: true,
        project: {},
        projectFetched: 0,
        slideDirection: 'left',
        slug: null,
      },
    },
    quiz: {
      initialState: {
        currentLocale: 'en',
        error: null,
        fullPath: '',
        loading: false,
        passScore: 0,
        questions: [],
        slideDirection: 'left',
      },
    },
    quizAttempt: {
      initialState: {
        user_id: 0,
        session_id: '0',
        id: null,
        locale: 'en',
        quiz_length: 0,
        quiz_version: 1,
        skipped: 0,
        correct: 0,
        incorrect: 0,
        completed: false,
        currentQuestion: 0,
        answers: {},
      },
    },
    user: {
      initialState: {
        error: null,
        loading: false,
      },
    },
  },
}
