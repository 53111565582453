import React from 'react'
import { Navigate, Route } from 'react-router-dom'

const loadRedirectedRoutes = (redirectedProjects) => {
  return redirectedProjects.map((redirect) => {
    return (
      <Route
        path={redirect.from}
        key={redirect.from}
        element={<Navigate to={redirect.to} />}
      />
    )
  })
}

export default loadRedirectedRoutes
