import config from 'helpers/config'
import {
  CLEAR_BADGE_STATE,
  FETCH_BADGE_REQUEST,
  RECEIVE_BADGE_TEMPLATE,
  RECEIVE_BADGE_TEMPLATE_ERROR,
  RECEIVE_ISSUED_BADGE,
  RECEIVE_ISSUED_BADGE_ERROR,
} from 'redux/constants/ActionTypes'

import { updateObject } from './utils'

const initialState = config.reducers.badge.initialState

export default function badge(state = initialState, action) {
  switch (action.type) {
    case CLEAR_BADGE_STATE: {
      return updateObject(state, initialState)
    }
    case FETCH_BADGE_REQUEST: {
      return updateObject(state, {
        error: null,
        loading: true,
        badge: {},
        badgeFetched: false,
        issued: false,
      })
    }
    case RECEIVE_ISSUED_BADGE: {
      return updateObject(state, {
        badge: action.response,
        badgeFetched: true,
        issued: true,
        loading: false,
      })
    }
    case RECEIVE_BADGE_TEMPLATE: {
      return updateObject(state, {
        badge: action.response,
        badgeFetched: true,
        issued: false,
        loading: false,
      })
    }
    case RECEIVE_ISSUED_BADGE_ERROR: {
      const fetchedBadge = action.error.body || {}
      return updateObject(state, {
        error: action.error,
        loading: false,
        badge: fetchedBadge,
      })
    }
    case RECEIVE_BADGE_TEMPLATE_ERROR: {
      const fetchedBadge = action.error.body || {}
      return updateObject(state, {
        error: action.error,
        loading: false,
        badge: fetchedBadge,
      })
    }
    default: {
      return state
    }
  }
}
