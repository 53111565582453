import config from 'helpers/config'
import {
  FETCH_COLLECTION_REQUEST,
  RECEIVE_COLLECTION,
  RECEIVE_COLLECTION_ERROR,
} from 'redux/constants/ActionTypes'

import { updateObject } from './utils'

const initialState = config.reducers.collection.initialState

export default function collection(state = initialState, action) {
  switch (action.type) {
    case FETCH_COLLECTION_REQUEST: {
      return updateObject(state, {
        ...config.reducers.collection.initialState,
        currentLocale: action.currentLocale || state.currentLocale,
        loading: true,
      })
    }
    case RECEIVE_COLLECTION: {
      const {
        pathways: { data: pathwaysData = [] } = {},
        additionalPathways: { data: additionalPathwaysData = [] } = {},
        pathwayProgress = {},
        additionalPathwayProgress = {},
        ...fetchedCollection
      } = action.response || {}
      const pathways = pathwaysData.map(
        ({ id, attributes, relationships }) => ({
          id,
          linkUrl: `/pathways/${attributes.slug}`,
          heroImage:
            attributes.heroImage ||
            `https://projects-static.raspberrypi.org/pathways/assets/${attributes.slug}.png`,
          ...attributes,
          relationships,
          progress: pathwayProgress[id]?.steps || [],
        })
      )
      const additionalPathways = additionalPathwaysData.map(
        ({ id, attributes, relationships }) => ({
          id,
          linkUrl: `/pathways/${attributes.slug}`,
          heroImage:
            attributes.heroImage ||
            `https://projects-static.raspberrypi.org/collections/assets/${fetchedCollection.slug}_placeholder.png`,
          ...attributes,
          relationships,
          progress: additionalPathwayProgress[id]?.steps || [],
        })
      )
      return updateObject(state, {
        ...fetchedCollection,
        pathways,
        additionalPathways,
        loading: false,
      })
    }
    case RECEIVE_COLLECTION_ERROR: {
      return updateObject(state, {
        ...config.reducers.collection.initialState,
        error: action.error,
        loading: false,
      })
    }
    default: {
      return state
    }
  }
}
