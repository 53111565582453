import Delay from 'components/shared/Delay/Delay'
import PropTypes from 'prop-types'
import React from 'react'

import './Loader.css'

const DEFAULT_LOADER_DELAY = 250

const Loader = ({ display = true, delay = DEFAULT_LOADER_DELAY }) => (
  <Delay wait={delay}>
    <div className={`computer display-${display}`}>
      <div className="screen" />
    </div>
  </Delay>
)

Loader.propTypes = {
  display: PropTypes.bool,
  delay: PropTypes.number,
}

export default Loader
