import { userManager } from 'helpers/authentication'
import { get, has } from 'lodash'

export const actionMiddleware = (store) => (next) => (action) => {
  const { authentication = {} } = store.getState()
  const signedInV0 = Boolean(authentication?.user?.profile?.uuid)
  if (signedInV0) {
    localStorage.setItem('authReturnUrl', '/')
    userManager.signinRedirect()
  }
  const actions = get(action, 'actions', {})
  if (actions && action?.type && has(actions, action.type)) {
    const childAction = get(actions, action.type)
    if (childAction?.action) {
      next(action)
      return store.dispatch(childAction.action)
    }
  }
  return next(action)
}

export default actionMiddleware
