import { CALL_API } from '../constants/ActionTypes'

export const createApiAction = ({
  method = 'GET',
  endpoint,
  types,
  data = {},
  ...extras
}) => {
  return {
    [CALL_API]: {
      method,
      endpoint,
      types,
      data,
      ...extras,
    },
  }
}
