import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import classnames from 'classnames'
import Routes from 'components/Routes'
import SessionCookie from 'components/SessionCookie/SessionCookie'
import HtmlHead from 'components/shared/HtmlHead/HtmlHead'
import PropTypes from 'prop-types'
import React from 'react'
import { translate } from 'react-i18next'

export const App = ({ i18n }) => {
  const gtmParams = { id: process.env.REACT_APP_GTM_ID }
  const classNames = classnames('c-i18n-root', {
    'c-i18n-root--rtl': i18n.dir() === 'rtl',
  })

  return (
    <GTMProvider state={gtmParams}>
      <div className={classNames} dir={i18n.dir()}>
        <SessionCookie />
        <HtmlHead i18n={i18n} />
        <Routes />
      </div>
    </GTMProvider>
  )
}

App.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default translate('translations')(App)
