import config from 'helpers/config'
import {
  FETCH_PATHWAY_REQUEST,
  RECEIVE_PATHWAY,
  RECEIVE_PATHWAY_ERROR,
} from 'redux/constants/ActionTypes'

import { updateObject } from './utils'

const initialState = config.reducers.pathway.initialState

export default function pathway(state = initialState, action) {
  switch (action.type) {
    case FETCH_PATHWAY_REQUEST: {
      return updateObject(state, {
        currentLocale: action.currentLocale || state.currentLocale,
        description: '',
        error: null,
        loading: true,
        projectsIds: [],
        title: '',
        slug: '',
        header: [],
      })
    }
    case RECEIVE_PATHWAY: {
      const fetchedPathway = action.response || {}
      return updateObject(state, {
        ...fetchedPathway,
        loading: false,
      })
    }
    case RECEIVE_PATHWAY_ERROR: {
      return updateObject(state, {
        error: action.error,
        loading: false,
        projectsIds: [],
      })
    }
    default: {
      return state
    }
  }
}
