import App from 'App'
// For IE 9-11 support
import 'core-js/es6/array'
import 'core-js/es6/map'
import 'core-js/es6/set'
import 'core-js/es6/symbol'
import 'core-js/es7/array'
import 'core-js/fn/object/values'
import 'index.css'
import 'raf/polyfill'
import * as Sentry from '@sentry/react'
import React from 'react'
import 'react-app-polyfill/ie9'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import middleware from 'redux/middleware'
import reducers from 'redux/reducers/index'

import ErrorBoundary from './ErrorBoundary'
import setupAuthentication, {
  OidcProvider,
  userManager,
} from './helpers/authentication'
import './helpers/i18n'

const supportsContainerQueries = 'container' in document.documentElement.style
if (!supportsContainerQueries) {
  import('container-query-polyfill')
}

window.addEventListener('CookiebotOnConsentReady', () => {
  if (window.Cookiebot.consent.statistics) {
    import('helpers/analytics/analytics').then((analytics) => analytics.init())
  }
})

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(...middleware))
)

if (process.env.REACT_APP_AUTHENTICATION === 'true') {
  setupAuthentication(store)
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <OidcProvider userManager={userManager} store={store}>
        <App />
      </OidcProvider>
    </Provider>
  </ErrorBoundary>
)
