import loadRedirectedRoutes from 'helpers/Redirects'

const legacyRoutes = [
  {
    from: '/en/projects/rpi-python-build-an-octapi',
    to: '/en/projects/build-an-octapi',
  },
  {
    from: '/en/projects/rpi-python-tweeting-babbage',
    to: '/en/projects/tweeting-babbage',
  },
  {
    from: '/en/projects/rpi-python-tweeting-babbage',
    to: '/en/projects/tweeting-babbage',
  },
  {
    from: '/en/projects/rpi-python-google-aiy',
    to: '/en/projects/google-voice-aiy',
  },
  {
    from: '/en/projects/rpi-python-gpio-music-box',
    to: '/en/projects/gpio-music-box',
  },
  {
    from: '/en/projects/rpi-python-hamster-party-cam',
    to: '/en/projects/hamster-party-cam',
  },
  {
    from: '/en/projects/rpi-python-octapi-public-key-cryptography',
    to: '/en/projects/octapi-public-key-cryptography',
  },
  {
    from: '/en/projects/rpi-python-parent-detector',
    to: '/en/projects/parent-detector',
  },
  {
    from: '/en/projects/rpi-python-whoopi-cushion',
    to: '/en/projects/whoopi-cushion',
  },
  {
    from: '/en/projects/generic-javascript-cat-meme-generator',
    to: '/en/projects/cat-meme-generator',
  },
  {
    from: '/en/projects/generic-scratch-space-maze',
    to: '/en/projects/space-maze',
  },
  {
    from: '/en/projects/rpi-python-people-in-space-indicator',
    to: '/en/projects/people-in-space-indicator',
  },
]

const loadLegacyRoutes = () => loadRedirectedRoutes(legacyRoutes)

export default loadLegacyRoutes
