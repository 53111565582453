import config from 'helpers/config'

const json = (response) => {
  return response.json().catch(() => {})
}

const status = (response) => {
  if (
    response.status >= config.okStatus &&
    response.status < config.redirectedStatus
  ) {
    return response
  }

  const err = new Error(response.statusText)
  err.status = response.status
  err.response = response
  throw err
}

const updateFromJson =
  ({ data, parseResponse = false }) =>
  (jsonResponse) => {
    if (parseResponse) {
      return {
        ...data,
        ...jsonResponse,
      }
    }
    return data
  }

export { json, status, updateFromJson }
