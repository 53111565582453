import config from 'helpers/config'

const headers = new Headers({
  'Content-Type': 'application/vnd.api+json',
})

const postFeedbackToAdmin = (messageAttributes) => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/feedbacks`

  return Promise.resolve(
    fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        data: {
          type: 'api/v1/feedbacks',
          attributes: messageAttributes,
        },
      }),
    })
      .then((response) => {
        if (response.status === config.createdStatus) {
          return response.json()
        }
        const err = new Error(response.statusText)
        err.response = response
        throw err
      })
      .catch((error) => console.error('Request failed:', error))
  )
}

export { postFeedbackToAdmin }
