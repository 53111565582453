import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import url from 'url'

export const dynamicCanonical = (currentUrl) => {
  const parsedtUrl = url.parse(currentUrl)
  var canonicalUrl = `${parsedtUrl.protocol}//${parsedtUrl.hostname}`
  canonicalUrl = parsedtUrl.port
    ? `${canonicalUrl}:${parsedtUrl.port}`
    : canonicalUrl
  canonicalUrl = parsedtUrl.path
    ? `${canonicalUrl}${parsedtUrl.pathname}`
    : canonicalUrl
  return canonicalUrl.replace(/\/+$/, '')
}

export const HtmlHead = ({ t, title, description, image, i18n }) => {
  const canonicalUrl = dynamicCanonical(window.location.href)
  return (
    <Helmet htmlAttributes={{ lang: i18n.language }}>
      <title>{title || t('meta.default.title')}</title>
      <meta
        name="description"
        content={description || t('meta.default.description')}
      />
      <meta property="og:title" content={title || t('meta.default.title')} />
      <meta
        property="og:description"
        content={description || t('meta.default.description')}
      />
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:url" content={canonicalUrl} />
      {image && <meta property="og:image" content={image} />}
    </Helmet>
  )
}

HtmlHead.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  i18n: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  return {
    profile: state?.authentication?.user?.profile || false,
  }
}

export default connect(mapStateToProps)(translate('translations')(HtmlHead))
