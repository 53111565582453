import { WebStorageStateStore } from 'oidc-client'
import {
  CallbackComponent,
  OidcProvider,
  createUserManager,
  loadUser,
  reducer,
} from 'redux-oidc'

const host = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ''
}`

const settings = {
  client_id: process.env.REACT_APP_AUTHENTICATION_CLIENT_ID,
  redirect_uri: `${host}/rpi/callback`,
  post_logout_redirect_uri: host,
  response_type: 'code',
  scope: 'openid profile force-consent allow-u13-login',
  authority: process.env.REACT_APP_AUTHENTICATION_URL,
  silent_redirect_uri: `${host}/rpi/silent_renew`,
  automaticSilentRenew: true,
  filterProtocolClaims: false,
  loadUserInfo: false,
  monitorSession: false,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  extraQueryParams: {
    brand: 'projects',
    login_options: 'v1_signup',
  },
}

const signUpQueryParams = {
  brand: 'projects',
  login_options: 'force_signup,v1_signup',
}

const userManager = createUserManager(settings)

userManager.events.addAccessTokenExpired(() => {
  // If the token has expired, trigger the silent renew process
  userManager.signinSilent()
})

const setupAuthentication = (store) => {
  loadUser(store, userManager)
}

export {
  CallbackComponent,
  OidcProvider,
  reducer,
  userManager,
  signUpQueryParams,
}

export default setupAuthentication
