import config from 'helpers/config'
import {
  ADD_ANSWER_TO_CURRENT_ATTEMPT,
  INIT_QUIZ_ATTEMPT,
  SEND_QUIZ_ANSWER_ERROR,
  SEND_QUIZ_ANSWER_REQUEST,
  SENT_QUIZ_ANSWER_OK,
  SET_CURRENT_QUESTION,
} from 'redux/constants/ActionTypes'

import { updateObject } from './utils'

const initialState = config.reducers.quizAttempt.initialState

export default function quiz(state = initialState, action) {
  switch (action.type) {
    case INIT_QUIZ_ATTEMPT: {
      return updateObject(initialState, {
        user_id: action.userId,
        session_id: action.sessionId,
        quiz_length: action.quizLength,
        quiz_version: action.quizVersion,
        locale: action.currentLocale || state.currentLocale,
      })
    }
    case SET_CURRENT_QUESTION: {
      const quizLength = state.quiz_length
      const { questionNumber } = action
      const currentQuestion = Math.min(Math.max(0, questionNumber), quizLength)
      return updateObject(state, {
        currentQuestion,
      })
    }
    case ADD_ANSWER_TO_CURRENT_ATTEMPT: {
      const { question, answer, result, attempt } = action
      return updateObject(state, {
        ...attempt,
        answers: {
          ...state.answers,
          [question]: [...(state.answers[question] || []), { answer, result }],
        },
      })
    }
    case SEND_QUIZ_ANSWER_REQUEST: {
      return updateObject(state, {
        error: null,
      })
    }
    case SENT_QUIZ_ANSWER_OK: {
      const attemptId = action.response.attempt_id || state.id
      return updateObject(state, {
        id: attemptId,
        error: null,
      })
    }
    case SEND_QUIZ_ANSWER_ERROR: {
      return updateObject(state, {
        error: action.error,
      })
    }
    default: {
      return state
    }
  }
}
