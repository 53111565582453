import config from 'helpers/config'
import {
  FETCH_FEATURED_REQUEST,
  RECEIVE_FEATURED,
  RECEIVE_FEATURED_ERROR,
} from 'redux/constants/ActionTypes'

import { updateObject } from './utils'

const initialState = config.reducers.featured.initialState

export default function featured(state = initialState, action) {
  switch (action.type) {
    case FETCH_FEATURED_REQUEST: {
      return updateObject(state, {
        currentLocale: action.currentLocale || state.currentLocale,
        error: null,
        loading: true,
      })
    }
    case RECEIVE_FEATURED: {
      const entities = action.response.projects || []
      const ids = Object.values(entities).map((entity) => entity.id)
      return updateObject(state, {
        entities,
        ids,
        loading: false,
      })
    }
    case RECEIVE_FEATURED_ERROR: {
      return updateObject(state, {
        entities: {},
        error: action.error,
        ids: [],
        loading: false,
      })
    }
    default: {
      return state
    }
  }
}
