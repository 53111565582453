import { get } from 'lodash'

// allows for a valid full url to be passed in via the action
export const isAbsoluteUrl = (string) => {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const normalizeRelationship = (
  json,
  normalizePath = 'data.attributes'
) => {
  const normalized = { ...get(json, normalizePath) }
  if (json.data.relationships) {
    Object.keys(json.data.relationships).map((type) => {
      return (normalized[`${type}Ids`] = json.data.relationships[type].data.map(
        (entity) => entity.id
      ))
    })
  }
  return normalized
}

export const normalize = (
  json,
  mapIds = false,
  normalizePath = 'data.attributes'
) => {
  if (!json.data) {
    return null
  }
  if (!Array.isArray(json.data)) {
    return normalizeRelationship(json, normalizePath)
  }
  const normalized = {}
  normalized.meta = {}
  normalized.links = {}
  if (json.data) {
    json.data.forEach((elem, ind) => {
      normalized[elem.type] = normalized[elem.type] || {}
      elem.attributes.id = elem.id
      if (mapIds) {
        normalized[elem.type][elem.id] = elem.attributes
      } else {
        normalized[elem.type][ind] = elem.attributes
      }
    })
  }
  if (json.links) {
    normalized.links = json.links
  }
  return normalized
}

export const nestedIncluded = (
  includedArray = [],
  nestedKey = 'type',
  nestedValue = 'attributes'
) => {
  const result = {}
  if (Array.isArray(includedArray)) {
    includedArray.forEach((includeElement) => {
      if (!(includeElement[nestedKey] in result)) {
        result[includeElement[nestedKey]] = []
      }
      result[includeElement[nestedKey]].push(includeElement[nestedValue])
    })
  }
  return result
}
