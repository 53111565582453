import {
  SEND_RECONCILE_USER_ERROR,
  SEND_RECONCILE_USER_REQUEST,
  SENT_RECONCILE_USER_OK,
} from 'redux/constants/ActionTypes'

import { createApiAction } from './ApiActions'

// API actions
export const reconcileUserBadges = ({ session_progress, authToken }) => {
  return createApiAction({
    endpoint: '/reconcile_users_badges',
    method: 'POST',
    types: [
      SEND_RECONCILE_USER_REQUEST,
      SENT_RECONCILE_USER_OK,
      SEND_RECONCILE_USER_ERROR,
    ],
    data: {
      session_progress,
    },
    authToken,
  })
}
