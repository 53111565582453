import config from 'helpers/config'
import {
  FETCH_FAVOURITES_REQUEST,
  RECEIVE_FAVOURITES,
  RECEIVE_FAVOURITES_ERROR,
  SEND_DELETE_FAVOURITES_REQUEST,
  SEND_FAVOURITES_ERROR,
  SEND_FAVOURITES_REQUEST,
  SENT_DELETE_FAVOURITES_OK,
  SENT_FAVOURITES_OK,
} from 'redux/constants/ActionTypes'

import { updateObject } from './utils'

const initialState = config.reducers.collections.initialState

export default function projects(state = initialState, action) {
  switch (action.type) {
    case FETCH_FAVOURITES_REQUEST: {
      return updateObject(state, {
        currentLocale: action.currentLocale || state.currentLocale,
        error: null,
        loading: true,
      })
    }
    case RECEIVE_FAVOURITES: {
      const entities = action.response.project || {}
      const ids = Object.values(entities).map((entity) => entity.id)
      return updateObject(state, {
        entities,
        ids,
        loading: false,
      })
    }
    case RECEIVE_FAVOURITES_ERROR: {
      return updateObject(state, {
        entities: {},
        error: action.error,
        ids: [],
        loading: false,
      })
    }
    case SEND_FAVOURITES_REQUEST:
    case SEND_DELETE_FAVOURITES_REQUEST: {
      return updateObject(state, {
        loading: true,
      })
    }
    case SENT_FAVOURITES_OK: {
      const ids = state.ids.slice()
      if (!ids.includes(action.response.id)) {
        ids.push(action.response.id.toString())
      }
      return updateObject(state, {
        entities: {
          ...state.entities,
          [action.response.id]: {
            ...state.entities[action.response.id],
          },
        },
        loading: false,
        ids,
      })
    }
    case SENT_DELETE_FAVOURITES_OK: {
      const ids = state.ids.filter(
        (item) => item !== action.response.id.toString()
      )

      const { [action.response.id]: _values, ...entities } = state.entities
      return updateObject(state, {
        entities,
        loading: false,
        ids,
      })
    }
    case SEND_FAVOURITES_ERROR: {
      return updateObject(state, {
        error: action.error,
        loading: false,
      })
    }
    default: {
      return state
    }
  }
}
