import classNames from 'classnames'
import { postFeedbackToAdmin } from 'helpers/Feedback'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { translate } from 'react-i18next'

import LicenseInfo from '../LicenseInfo/LicenseInfo'
import './Footer.css'

export const Footer = ({ text, userID, projectSlug, t }) => {
  const [feedbackFormDisabled, setFeedbackFormDisabled] = useState(false)
  const [feedbackText, setFeedbackText] = useState('')
  const [feedbackVisibleElement, setFeedbackVisibleElement] = useState('prompt')
  const textAreaReference = useRef(null)

  const links = [
    {
      key: 'accessibility',
      text: 'project.footer.license.accessibility',
      href: 'https://www.raspberrypi.org/accessibility/',
    },
    {
      key: 'cookies',
      text: 'project.footer.license.cookies',
      href: 'https://www.raspberrypi.org/cookies/',
    },
    {
      key: 'privacy',
      text: 'project.footer.license.privacy',
      href: 'https://www.raspberrypi.org/privacy/',
    },
    {
      key: 'translations',
      text: 'project.footer.translations.text',
      href: '/en/projects/translating-for-raspberry-pi',
    },
  ]

  const isProject = typeof projectSlug === 'string'
  const projectRepoUrl = projectSlug
    ? `https://github.com/RaspberryPiLearning/${projectSlug}`
    : null

  const formStep = 5

  const buildFeedbackText = isProject
    ? `URL: ${window.location.pathname}\n\n\n${feedbackText}`
    : feedbackText

  const feedbackClassNames = (element) => {
    return classNames({
      [`c-project-footer__feedback-${element}`]: true,
      [`c-project-footer__feedback-${element}--hidden`]:
        element !== feedbackVisibleElement,
    })
  }

  const feedbackPromptClickHandler = () => setFeedbackVisibleElement('form')

  const updateFeedbackText = (event) => {
    setFeedbackText(event.target.value)
  }

  const feedbackFormSubmitHandler = (event) => {
    event.preventDefault()
    setFeedbackFormDisabled(true)
  }

  const postFeedback = useCallback(async () => {
    const attributes = {
      message: buildFeedbackText,
      userID,
    }

    if (isProject) {
      attributes.project_slug = projectSlug
      attributes.step_number = formStep
    }
    await postFeedbackToAdmin(attributes)
    setFeedbackVisibleElement('thanks')
  }, [buildFeedbackText, isProject, projectSlug, userID])

  useEffect(() => {
    if (feedbackFormDisabled) {
      postFeedback()
    }
  }, [feedbackFormDisabled, postFeedback])

  useEffect(() => {
    if (feedbackVisibleElement === 'form') {
      textAreaReference.current.focus()
    }
  }, [feedbackVisibleElement, textAreaReference])

  return (
    <footer className="c-project-footer">
      <p>{t(text)}</p>

      <button
        className={feedbackClassNames('prompt')}
        onClick={feedbackPromptClickHandler}
      >
        {t('project.footer.feedback.button.text')}{' '}
      </button>

      <form
        className={feedbackClassNames('form')}
        onSubmit={feedbackFormSubmitHandler}
      >
        <textarea
          ref={textAreaReference}
          className="c-project-footer__feedback-textarea"
          aria-labelledby="textarea-label"
          cols="40"
          disabled={feedbackFormDisabled}
          onChange={updateFeedbackText}
          placeholder={`${t('project.footer.feedback.placeholder.text')}`}
          rows="6"
          value={feedbackText}
        />
        <p id="textarea-label">{t('project.footer.feedback.prompt.text')}</p>
        <button
          className="c-project-footer__feedback-submit"
          disabled={feedbackFormDisabled || !feedbackText}
          type="submit"
        >
          {feedbackFormDisabled
            ? t('project.footer.feedback.button.disabled.text')
            : t('project.footer.feedback.button.text')}
        </button>
      </form>

      <div className={feedbackClassNames('thanks')}>
        {t('project.footer.feedback.thank-you.text')}
      </div>

      <LicenseInfo projectRepoUrl={projectRepoUrl} />
      <div className="c-project-footer-policy">
        {links.map((link) => (
          <p key={link.key}>
            <a className="c-project-footer__link" href={link.href}>
              {t(link.text)}
            </a>
          </p>
        ))}
      </div>
    </footer>
  )
}

Footer.propTypes = {
  projectSlug: PropTypes.string,
  t: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  userID: PropTypes.string,
}

export default translate('translations')(Footer)
