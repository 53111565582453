import config from 'helpers/config'
import {
  FETCH_EXERCISE_REQUEST,
  RECEIVE_EXERCISE,
  RECEIVE_EXERCISE_ERROR,
} from 'redux/constants/ActionTypes'

import { updateObject } from './utils'

const initialState = config.reducers.collection.initialState

export default function collection(state = initialState, action) {
  switch (action.type) {
    case FETCH_EXERCISE_REQUEST: {
      return updateObject(state, {
        ...config.reducers.collection.initialState,
        currentLocale: action.currentLocale || state.currentLocale,
        loading: true,
      })
    }
    case RECEIVE_EXERCISE: {
      const { slug, ...fetchedCollection } = action.response || {}

      return updateObject(state, {
        ...fetchedCollection,
        loading: false,
      })
    }
    case RECEIVE_EXERCISE_ERROR: {
      return updateObject(state, {
        ...config.reducers.collection.initialState,
        error: action.error,
        loading: false,
      })
    }
    default: {
      return state
    }
  }
}
