import { userManager } from 'helpers/authentication'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import { reconcileUserBadges } from 'redux/actions/UserActions'

import NavItemLink from '../NavItemLink/NavItemLink'

const profileURL = process.env.REACT_APP_PROFILE_URL

const redirectToLogIn = (e) => {
  e.preventDefault()
  localStorage.setItem('authReturnUrl', window.location.href)
  userManager.signinRedirect()
}

const redirectToLogOut = (removeCookie, authentication) => (e) => {
  e.preventDefault()
  removeCookie('sessionProgress', { path: '/' })
  userManager.signoutRedirect({ id_token_hint: authentication?.user?.id_token })
  userManager.removeUser()
}

export const UserStatus = ({ authentication, dispatch, locale, t }) => {
  const [cookies, _setCookie, removeCookie] = useCookies(['sessionProgress'])
  const [userStatus, setUserStatus] = useState('loggedOut')

  const userNavItems = {
    loggedIn: {
      yourProjects: {
        to: 'projects/progress',
        locale,
        className: 'c-site-header__nav-link--button',
      },
      profile: { to: profileURL, external: true },
      logout: {
        to: 'logout',
        onClick: redirectToLogOut(removeCookie, authentication),
      },
    },
    loggedOut: {
      login: { to: 'login', onClick: redirectToLogIn },
    },
  }

  useEffect(() => {
    if (authentication?.user?.access_token && cookies.sessionProgress) {
      dispatch(
        reconcileUserBadges({
          session_progress: cookies.sessionProgress,
          authToken: authentication.user.access_token,
        })
      )
    }
  }, [authentication, cookies.sessionProgress, dispatch])

  useEffect(() => {
    if (authentication.user) {
      setUserStatus('loggedIn')
    } else {
      setUserStatus('loggedOut')
    }
  }, [authentication.user])

  return (
    <>
      {Object.entries(userNavItems[userStatus]).map(([key, value]) => (
        <NavItemLink
          key={`${key}-item`}
          listKey={`${key}-item`}
          linkTo={value.to}
          translateKey={key}
          t={t}
          {...(value.external && { external: value.external })}
          {...(value.onClick && { onClick: value.onClick })}
          {...(value.locale && { locale })}
          {...(value.className && { className: value.className })}
        />
      ))}
    </>
  )
}

UserStatus.propTypes = {
  authentication: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const { authentication } = state
  return {
    authentication,
  }
}

export default translate('translations')(connect(mapStateToProps)(UserStatus))
